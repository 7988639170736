import React, { useEffect, useState } from 'react';
import Image from 'next/image';
import { AmplienceCtaContentData, AmplienceImageCard } from '@Types/amplience/amplienceComponents';
import classNames from 'classnames';
import ReactMarkdown from 'react-markdown';
import { useFormat } from 'helpers/hooks/useFormat';
import { amplienceImageLoader } from 'helpers/imageLoaders/amplienceImageLoader';
import { createImageUrlFromAmplienceImageCard } from 'helpers/utils/amplienceUtils';
import AmplienceButton from '../amplience-button';
import styles from './amplience-asymmetrical-component.module.scss';

export interface Props {
  content: {
    image: AmplienceImageCard;
    imagePosition: 'Left' | 'Right';
    subheading: {
      colour: string;
      text: string;
    };
    heading: {
      colour: string;
      text: string;
    };
    content: {
      text: [
        {
          type: 'markdown';
          data: string;
        },
      ];
      colour: string;
    };
    buttons: { button: AmplienceCtaContentData }[];
    backgroundColour: string;
    vertialContentAligment: 'Bottom' | 'Top';
  };
}

const AmplienceAsymmetricalContent = (props: Props) => {
  const [contentText, setContentText] = useState(props.content.content.text[0].data);
  const [showReadMore, setShowReadMore] = useState(false);
  const [showReadLess, setShowReadLess] = useState(false);
  const { formatMessage } = useFormat({ name: 'common' });

  const isAlignedTop = props.content.vertialContentAligment === 'Top';
  const maxCharacterLength = 200;

  useEffect(() => {
    if (props.content.content.text[0].data.length > maxCharacterLength) {
      setContentText(props.content.content.text[0].data.substring(0, maxCharacterLength) + '...');
      setShowReadMore(true);
    }
  }, []);

  useEffect(() => {
    if (showReadLess) {
      setContentText(props.content.content.text[0].data);
    } else if (!showReadLess && props.content.content.text[0].data.length > maxCharacterLength) {
      setContentText(props.content.content.text[0].data.substring(0, maxCharacterLength) + '...');
    }
  }, [showReadLess]);

  return (
    <div
      data-testid="asymmetrical-component-wrapper"
      className={classNames(styles.wrapper, {
        [styles.wrapper_showMoreActive]: showReadLess,
        [styles.wrapper_isAlignedTop]: isAlignedTop,
        [styles.wrapper_isReverse]: props.content.imagePosition === 'Right',
      })}
    >
      <div
        className={classNames(styles.imageContentWrapper, {
          [styles.imageContentWrapper_alignedTop]: isAlignedTop,
          [styles.imageContentWrapper_showMoreActive]: showReadLess,
        })}
        style={{
          backgroundColor: `${props.content?.backgroundColour ?? '#fff'}`,
        }}
      >
        {props.content?.image && (
          <div
            className={classNames(styles.imageWrapper, {
              [styles.imageWrapper_isAlignedTop]: isAlignedTop,
            })}
          >
            <Image
              className={styles.image}
              loader={({ src, width }) => amplienceImageLoader('General', src, width)}
              sizes="(max-width: 768px) 90vw, 50vw"
              src={createImageUrlFromAmplienceImageCard(props.content?.image)}
              alt={props.content?.image?.metaTextAleternative}
              layout="fill"
              data-testid="asymmetrical-component-image"
            ></Image>
          </div>
        )}
      </div>
      <div
        className={classNames(styles.textContentWrapper, {
          [styles.textContentWrapper_isAlignedBottom]: !isAlignedTop,
        })}
      >
        <div
          className={classNames(styles.background, {
            [styles.background_readLessIsActive]: showReadLess,
          })}
          style={{
            backgroundColor: `${props.content?.backgroundColour ?? '#fff'}`,
          }}
        >
          <div className={classNames(styles.content, { [styles.content_readLessIsActive]: showReadLess })}>
            {props.content?.subheading?.text && (
              <h2
                className={styles.subheading}
                style={{ color: props.content?.subheading.colour }}
                data-testid="asymmetrical-component-heading"
              >
                {props.content?.subheading.text}
              </h2>
            )}
            {props.content?.heading?.text && (
              <h1
                className={styles.heading}
                style={{ color: props.content?.heading.colour }}
                data-testid="asymmetrical-component-subheading"
              >
                {props.content?.heading.text}
              </h1>
            )}
            {props.content?.content?.text && (
              <div style={{ color: props.content.content.colour }} data-testid="asymmetrical-component-content">
                <ReactMarkdown className={styles.markdown}>{contentText}</ReactMarkdown>
                {showReadMore && (
                  <p
                    data-testid="asymmetrical-component-readmore"
                    className={styles.readMore}
                    onClick={() => {
                      setShowReadMore(false);
                      setShowReadLess(true);
                    }}
                  >
                    {formatMessage({
                      id: 'read.more',
                      defaultMessage: 'Read more',
                    })}
                  </p>
                )}
                {showReadLess && (
                  <p
                    data-testid="asymmetrical-component-readless"
                    className={styles.readMore}
                    onClick={() => {
                      setShowReadMore(true);
                      setShowReadLess(false);
                    }}
                  >
                    {formatMessage({
                      id: 'read.less',
                      defaultMessage: 'Read less',
                    })}
                  </p>
                )}
              </div>
            )}
            {props.content?.buttons?.length > 0 && (
              <div className={styles.buttonsWrapper}>
                {props.content?.buttons.map((button, index) => (
                  <AmplienceButton
                    key={'asymetrical-component-' + button.button.link + '-' + index}
                    content={button.button}
                  />
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AmplienceAsymmetricalContent;
