import React, { FC, useContext, useEffect, useState } from 'react';
import { ArrowRightIcon } from '@heroicons/react/outline';
import { AmplienceCtaContentData } from '@Types/amplience/amplienceComponents';
import NextLink from 'components/common/NextLink';
import { LayoutContext } from 'frontastic/provider/layout';
import styles from './amplience-button.module.scss';

export interface AmplienceButtonProps {
  content: AmplienceCtaContentData;
}

const AmplienceButton: FC<AmplienceButtonProps> = ({ content }) => {
  const [hover, setHover] = useState(false);
  const [buttonText, setButtonText] = useState(content?.text);
  const layout = useContext(LayoutContext);

  const handleMouseEnter = () => {
    if (content.hoverColour) {
      setHover(true);
    }
  };
  const handleMouseLeave = () => {
    setHover(false);
  };

  const buttonTextFunc = () => {
    if (layout.isMobile && content.textMobile) {
      setButtonText(content.textMobile);
    } else {
      setButtonText(content.text);
    }
  };

  useEffect(() => {
    buttonTextFunc();
  }, [layout]);

  return (
    <NextLink href={content.link}>
      <div
        onMouseEnter={() => handleMouseEnter()}
        onMouseLeave={() => handleMouseLeave()}
        className={styles.button}
        style={{
          backgroundColor: hover ? content.hoverColour : content.backgroundColour,
          color: content.textColour,
          textDecoration: content.isUnderlined ? 'underline' : 'none',
          borderColor: content.borderColour,
          borderRadius: content.isRounded ? 30 : 0,
          paddingBlock: content.verticalPadding,
          paddingInline: content.horizontalPadding,
        }}
        data-testid={`btn-${content.text}`}
      >
        {buttonText}
        {content.appendArrowIcon && <ArrowRightIcon width={11} color={content.textColour} className={styles.arrow} />}
      </div>
    </NextLink>
  );
};

export default AmplienceButton;
